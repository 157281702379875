import { useState, useEffect, useRef } from 'react'
import Link from 'next/link'
// import { getRoute } from 'utils/helpers'
import { get } from 'utils/api'
import styles from './home-banner.module.scss'
import Image from 'components/image/image'

import { cn } from 'utils/helpers'
import Btn from 'components/btn/btn'

const delay = 3500

// import Icon from 'components/svg/svg'
// <div className={styles.next}>
//     <Icon name="right" />
// </div>

export default function HomeBanner() {
    // const url = getRoute('buyerblog')
    const [active, setActive] = useState(0)
    const [items, setItems] = useState([])
    const time = useRef()
    const count = useRef()
    const act = useRef(0)

    const init = async () => {
        const response = await get('home-banners')
        if (response && response.status === 200 && response?.data?.data?.banners?.length) {
            setItems(response.data.data.banners)
            count.current = response.data.data.banners.length
            if (count.current > 1) {
                time.current = setTimeout(() => {
                    next()
                }, delay)
            }
        }
    }

    const next = () => {
        if (act.current === count.current - 1) {
            set(0)
        } else {
            set(act.current + 1)
        }
    }

    const set = nr => {
        setActive(nr)
        act.current = nr
        if (time.current) {
            clearTimeout(time.current)
        }
        time.current = setTimeout(() => {
            next()
        }, delay)
    }

    useEffect(init, [])

    if (!items.length) return null

    return (
        <div className={styles.heroBanners}>
            <div className={cn(styles.bubble, styles.bubble1)} />
            <div className={cn(styles.bubble, styles.bubble2)} />
            <div className={cn(styles.bubble, styles.bubble3)} />
            <div className={styles.badge}>NAUJIENA</div>
            <div className={styles.bannerCards}>
                {items.map((item, k) => (
                    <div key={k} className={styles.bannerCard}>
                        <div key={k} className={cn(styles.bannerCardContent, active === k && styles.bannerCardActive)}>
                            <a href={item.url} aria-label={item.name} />
                            <div className={styles.bannerImg}>
                                <Image
                                    src={item.image}
                                    alt={item.name}
                                    layout="responsive"
                                    objectFit="cover"
                                    width={350}
                                    height={350}
                                />
                            </div>
                            <div className={styles.bannerContent}>
                                <div className={styles.bannerName}>
                                    <Link href={item.url}>{item.name}</Link>
                                </div>
                                <div className={styles.bannerDesc}>{item.description}</div>
                                <div className={styles.bannerBtn}>
                                    <Btn href={item.url} style="yellow">
                                        Rinktis
                                    </Btn>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                <div className={styles.bannerBullets}>
                    {items.length > 1 &&
                        items.map((item, k) => (
                            <i key={k} className={cn(active === k && styles.active)} onClick={() => set(k)}>
                                <i></i>
                            </i>
                        ))}
                </div>
            </div>
        </div>
    )
}
